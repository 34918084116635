@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-bg: #242526;
  --secondary-bg: rgb(212, 227, 227);
  --primary-text-color: #555;
  --border-radius: 8px;
  --speed: 500ms;
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul{
  list-style: none;
}

.dropdown-profile {
  position: absolute;
  top: 55px;
  width: 200px;
  background-color: #fff;
  border: 1px solid #D7D7D7 ;
  border-radius: 5px;
}

.dropdown-profile.active {
  opacity: 1;
  visibility:  visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-profile.inactive {
  opacity: 0;
  visibility:  hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.dropdown-profile ul li:hover a{
  background: rgb(212, 227, 227);
  color: #00a5a8;
  cursor: pointer;
  border-radius: 6px;
  flex: auto;
}

.dropdown-profile-items {
  display: flex;
  margin: 5px auto ;
}

.dropdown-profile-items a {
  max-width: 200px;                                                                                                                                                                                                                                                                                                                                                                                   
  margin: 0 10px;
  transition: var(--speed);
  padding: 5px;
  font-family: sarabun;
}

.swal2-popup {
  font-family: sarabun;
}